@font-face {
  font-family: 'europa';
  src: local('europa'), url('../fonts/EuropaRegular.ttf') format('truetype');
  font-weight: normal;
}

body {
  font-family: 'europa';
  font-style: normal;
  font-weight: normal;
}
